@use "src/_app/styles/mixins.scss" as *;
.difference {
  &__container {
    width: 100%;
    max-width: 976px;
    margin: auto;
  }

  &__title {
    @include tTitle_1_form;
    color: var(--primary);
    margin: 0 0 68px;
    max-width: 892px;

    @include respond(tablet) {
      margin-bottom: 32px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 485px 1fr;
    column-gap: 70px;
    align-items: center;

    @include respond(laptop) {
      column-gap: 41px;
      grid-template-columns: 451px 1fr;
    }

    @include respond(tablet) {
      grid-template-columns: 1fr;
      row-gap: 32px;
    }
  }

  &__convert {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 86px 0;

    border-radius: 24px;
    background-color: var(--bg_6);
    border: 1px solid var(--border_15);

    @include main_shadow;

    @include respond(tablet) {
      padding: 24px 0;
    }

    &__iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 106px;
      height: 106px;

      background-color: var(--success);
      border-radius: 100%;
      margin-bottom: 24px;

      color: var(--secondary);
    }

    &__subtitle {
      @include tTitle_3;
      color: var(--primary);
      margin: 0 0 8px;
    }

    &__exchange {
      margin: 0 0 32px;

      @include tTitle_2_Semibold;
      color: var(--primary);

      @include respond(tabletS) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    color: var(--primary);

    @include respond(tablet) {
      row-gap: 16px;
    }

    p {
      margin: 0;
      color: var(--primary);

      @include tTitle_3-demibold;
      font-weight: 400;
    }
  }
}
