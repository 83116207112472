@use "src/_app/styles/mixins.scss" as *;
.container {
  background: var(--bg_1);
  overflow: hidden;
  position: relative;

  &.white {
    .wrapper {
      &::before {
        left: 0;
        background: linear-gradient(to right, var(--secondary), transparent);
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, var(--secondary), transparent);
      }
    }
    background: var(--secondary);

    .section {
      color: var(--primary);

      .swiper__slide {
        background: var(--bg_2) !important;
      }

      .header__controls {
        .swiper_button {
          svg path {
            fill: var(--primary);
          }

          &:hover {
            background-color: var(--btnSecondaryHover);
          }

          &:active {
            background-color: var(--btnSecondaryActive);
          }
        }
      }
    }
  }

  &.secondary {
    .wrapper {
      &::before {
        left: 0;
        background: linear-gradient(to right, var(--bg_3), transparent);
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, var(--bg_3), transparent);
      }
    }
    background: var(--bg_3);

    .section {
      .swiper__slide {
        background: var(--secondary);
        box-shadow: 0 8px 16px 0 #2929290a;
      }

      .header__controls {
        .swiper_button {
          background: var(--bg_6);
        }
      }
    }
  }

  .wrapper {
    max-width: 1452px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100px; /* Ширина градиента */
      z-index: 99;

      @include respond(laptopL) {
        width: 80px;
      }

      @include respond(laptop) {
        width: 32px;
      }

      @include respond(tabletS) {
        width: 16px;
      }
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, var(--bg_1), transparent);
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, var(--bg_1), transparent);
    }

    @include respond(laptopL) {
      max-width: 100%;
    }
  }

  .section {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    padding: 100px 0;

    @include respond(laptopL) {
      max-width: 100%;
      padding: 100px 80px;
      margin: 0;
    }

    @include respond(laptop) {
      padding: 100px 32px;
    }

    @include respond(tablet) {
      padding: 40px 14px;
    }

    .section__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .header__info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .header__title {
          margin: 0;

          @include tTitle_1_main;

          @include respond(tablet) {
            @include tTitle_2_Semibold;
          }
        }

        .header__description {
          margin: 0;

          @include tSubtitle;
          max-width: 768px;
        }
      }

      .header__controls {
        display: flex;
        flex-flow: row nowrap;
        gap: 24px;

        @include respond(tablet) {
          display: none;
        }

        .swiper_button {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid rgb(255 255 255 / 30%);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          cursor: pointer;

          @include transition;

          &:disabled {
            opacity: 0.7;
            pointer-events: none;
          }

          &:hover {
            background-color: rgb(255 255 255 / 30%);
          }

          &:active {
            background-color: rgb(255 255 255 / 15%);
          }

          &.forward_button {
            @include rtl {
              transform: rotate(180deg);
            }
          }

          &.back_button {
            @include ltr {
              transform: rotate(180deg);
            }
          }

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: var(--mainSecondary);
            }
          }

          &_white {
            border: 1px solid var(--border_swiper_button);

            &:hover {
              background-color: var(--blogRateBorder);
            }

            svg {
              path {
                fill: var(--border_2_inverted);
              }
            }
          }
        }
      }
    }

    .swiper_list {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: visible;

      @include respond(tablet) {
        height: auto;
      }

      &.s {
        height: 278px;

        @include respond(tablet) {
          height: auto;
        }
      }

      &.long_lang {
        height: 390px;

        @include respond(laptopL) {
          height: 450px;
        }

        @include respond(tablet) {
          height: auto;
          max-height: none;
        }

        .swiper__slide {
          height: 390px;

          @include respond(laptopL) {
            height: 450px;
          }

          @include respond(tablet) {
            height: auto;
            max-height: none;
          }
        }
      }

      &.long_lang_s {
        height: 330px;

        @include respond(laptopL) {
          height: 390px;
        }

        @include respond(tablet) {
          height: auto;
          max-height: none;
        }

        .swiper__slide {
          height: 330px;

          @include respond(laptopL) {
            height: 390px;
          }

          @include respond(tablet) {
            height: auto;
            max-height: none;
          }
        }
      }

      .swiper__slide {
        height: 330px;
        width: 100%;
        max-width: 688px;
        border-radius: 24px;
        background: var(--bg_1_reverse);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.slide_s {
          max-width: 519px;
          height: 278px;
        }

        @include respond(tablet) {
          gap: 16px;
          height: auto;
          width: 318px;
          padding: 24px 16px;
        }

        .slide__title {
          margin: 0;

          @include tTitle_3-semibold;

          @include respond(tablet) {
            @include tSubtitle_Semibold;
          }
        }

        .slide__description {
          margin: 0;

          @include tSubtitle;
        }
      }
    }
  }
}
