@use "src/_app/styles/mixins.scss" as *;
.root {
  background: var(--bg_6);
  border-radius: 24px;
  padding: 24px;
}

.icon {
  width: 62px;
  height: 62px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: var(--bg_2);

  &_arrow {
    transform: rotate(45deg);
  }
}

.title {
  margin: 0 0 8px;

  @include tTitle_3-demibold;

  @include respond(laptop) {
    font-size: 16px;
    line-height: 24px;
  }
}

.text {
  margin: 0;

  @include tSubtitle;

  @include respond(laptop) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.12px;
  }
}
