@use "src/_app/styles/mixins.scss" as *;
.advantages {
  max-width: 1062px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 24px;
  margin: 0 auto;

  @include respond(tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__item {
    height: 100%;

    @include main_shadow;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 24px;
      padding: 1px;
      background: var(--border__processing_advantages);
      /* stylelint-disable-next-line */
      mask: linear-gradient(var(--primary) 0 0) content-box, linear-gradient(var(--primary) 0 0);
      mask-composite: exclude;
    }

    &:first-child {
      grid-area: 1 / 1 / 2 / 4;
    }

    &:nth-child(2) {
      grid-area: 1 / 4 / 2 / 6;
    }

    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
    }

    &:last-child {
      grid-area: 2 / 3 / 3 / 6;
    }
  }
}
