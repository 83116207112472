@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  padding: 80px;
  background: var(--bg_mass_block);

  @include respond(tabletL) {
    padding: 100px 80px;
  }

  @include respond(tabletS) {
    padding: 40px 32px;
  }

  @include respond(mobileL) {
    padding: 40px 16px;
  }

  .faq_block {
    max-width: 980px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 56px;
    color: var(--primary);

    @include respond(tablet) {
      row-gap: 16px;
    }

    &__title {
      margin: 0;

      @include tTitle_1_form;
    }
  }
}
