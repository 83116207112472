@use "src/_app/styles/mixins.scss" as *;
.whenToUse {
  max-width: 1280px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  row-gap: 68px;
  justify-content: flex-start;

  color: var(--primary);

  @include respond(tablet) {
    row-gap: 32px;
  }

  &__title {
    margin: 0;

    @include tTitle_1_main;

    @include respond(tablet) {
      @include tTitle_2_Semibold;
    }
  }

  &__slider {
    width: 100%;
    overflow: visible;
  }

  &__item {
    height: 100%;
  }
}

.icon_arrow {
  transform: rotate(45deg);
}
