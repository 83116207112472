@use "src/_app/styles/mixins.scss" as *;
.header {
  margin: 0 0 48px;
  max-width: 340px;
  color: var(--white);

  @include tTitle_1_main;

  @include respond(laptop) {
    max-width: none;
  }

  @include respond(tabletS) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}

.advantages__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item__text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);

  .item__title {
    margin: 0;

    @include tSubtitle_Semibold;
  }

  .item__descr {
    @include tCaption;
  }
}

.advantages {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  color: var(--white);

  .swiper_slide {
    padding: 0;
  }

  &_mobile {
    display: none;

    @include respond(tabletS) {
      display: block;
    }
  }

  &_desktop {
    display: grid;

    @include respond(tabletS) {
      display: none;
    }

    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    @include respond(laptop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.advantages__item {
  height: 100%;
  padding: 24px 24px 40px;
  background: var(--bg_1);

  @include sElevation_6;
  border-radius: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1px;
    background: #141414;
    background: linear-gradient(
        180deg,
        rgb(39 39 39 / 100%),
        rgb(20 20 20 / 100%)
      )
      border-box;
    /* stylelint-disable-next-line */
    mask: linear-gradient(var(--bg_1_reverse) 0 0) content-box, linear-gradient(var(--bg_1_reverse) 0 0);
    mask-composite: exclude;
  }

  .item__icon {
    width: 48px;
    height: 48px;
    background: var(--bg_whitelabel_card_icon);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    svg path {
      stroke: var(--white);
    }
  }
}
