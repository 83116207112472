@use "src/_app/styles/mixins.scss" as *;
.coinsSlider {
  &__container {
    max-width: 1440px;
    margin: auto;
  }

  &__text {
    text-align: center;
    margin-bottom: 68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(tablet) {
      margin-bottom: 32px;
    }

    @include respond(tablet) {
      padding: 0 16px;
    }

    &__title {
      @include tTitle_1_main;
      color: var(--primary);
      max-width: 1170px;
      margin: 0 0 24px;

      @include respond(laptop) {
        font-size: 40px;
      }

      @include respond(tabletS) {
        text-align: left;

        @include tTitle_2_Semibold;
      }
    }

    &__subTitle {
      color: var(--primary);
      margin: 0;

      @include tTitle_3;
      max-width: 894px;

      @include respond(laptop) {
        font-size: 16px;
      }

      @include respond(tabletS) {
        text-align: left;
      }
    }
  }
}
