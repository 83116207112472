@use "src/_app/styles/mixins.scss" as *;
.get {
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 120px 80px;

  @include respond(laptop) {
    padding: 40px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
  }

  .get__title {
    margin: 0;

    @include tTitle_1_block;

    @include respond(tablet) {
      font-size: 36px;
      line-height: 42px;
    }
  }

  .get__list {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 25px;

    @include respond(tablet) {
      flex-direction: column;
      row-gap: 16px;
    }

    .list__item {
      width: 100%;
      padding: 24px;
      height: 254px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #1a1a1a;
      border: 1px solid #2d2d2d;
      border-radius: 20px;
      z-index: 10;

      @include respond(tablet) {
        height: 196px;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .item__text {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .text__title {
        margin: 0;

        line-height: 34px;

        @include tTitle_2_semibold;

        @include respond(tablet) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      .text__description {
        margin: 0;

        @include tCaption;
      }
    }
  }
}
