@use "src/_app/styles/mixins.scss" as *;
.container {
  background: var(--secondary);

  .what {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    color: var(--primary);

    border-top: 1px solid var(--line_color);

    padding: 100px 0;
    margin: 0 auto;

    @include respond(laptopL) {
      padding: 100px 0;
      margin: 0 80px;
    }

    @include respond(laptop) {
      padding: 100px 0;
      margin: 0 32px;
    }

    @include respond(tablet) {
      gap: 24px;
      padding: 40px 0;
      margin: 0 16px;
    }

    &__header {
      max-width: 1005px;

      .header__title {
        @include tTitle_1_main;
        margin: 0 0 24px;

        @include respond(laptop) {
          margin-bottom: 16px;
        }

        @include respond(tablet) {
          @include Land_28;
          margin-bottom: 8px;
        }
      }

      .header__description {
        margin: 0;

        @include tTitle_3;
        letter-spacing: normal;

        @include respond(laptop) {
          font-size: 16px;
        }
      }
    }

    &__row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      column-gap: 57px;

      @include respond(tablet) {
        flex-direction: column;
      }

      &.row_right {
        flex-direction: row-reverse;

        @include respond(tablet) {
          flex-direction: column;
        }
      }

      img {
        max-width: 628px;
        max-height: 481px;
        overflow: hidden;
        object-fit: cover;

        @include main_shadow;
        min-width: 40%;
        border-radius: 24px;
        height: fit-content;
        margin: auto 0;

        @include respond(tablet) {
          max-width: 100%;
          margin-bottom: 24px;
        }
      }

      .row__content {
        width: 45%;
        margin: auto 0;
        flex-shrink: 0;

        @include respond(tablet) {
          width: 100%;
        }

        .row__title {
          @include Land_32;
          margin: 0;

          @include respond(laptop) {
            @include tTitle_2_Semibold;
          }

          @include respond(tablet) {
            @include tSubtitle-3-medium;
          }
        }

        .row__description {
          @include tSubtitle;
          margin: 16px 0 24px;

          @include respond(tablet) {
            margin-top: 8px;
          }
        }

        .row__btn {
          padding: 14px 20px;
          font-weight: 600;

          @include respond(tablet) {
            margin-bottom: 16px;
          }
        }

        .advantages__list {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          margin: 24px 0;
          list-style: none;
          padding: 0;

          @include respond(laptop) {
            row-gap: 16px;
            margin: 16px 0;
          }

          .list__item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            column-gap: 12px;

            @include tTitle_3-semibold;
            font-size: 16px;

            svg {
              flex-shrink: 0;

              path {
                fill: var(--primary);
              }
            }
          }
        }

        .row_footer {
          padding-top: 24px;
          border-top: 1px solid var(--line_color);
          color: var(--text_4);

          @include tCaption;

          @include respond(tablet) {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
