@use "src/_app/styles/mixins.scss" as *;
.block__container {
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  max-width: 1064px;
  width: 100%;
  margin: auto;

  @include respond(laptopC) {
    max-width: 960px;
  }

  @include respond(laptop) {
    max-width: 100%;
    row-gap: 56px;
  }

  @include respond(tablet) {
    row-gap: 4px;
  }

  .title {
    @include tTitle_1_main;
    color: var(--primary);
    margin: 0;

    @include respond(laptop) {
      @include tTitle_1_form;
    }
  }
}
