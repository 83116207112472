@use "src/_app/styles/mixins.scss" as *;
.slider {
  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
    color: var(--primary);
    background-color: var(--bg_42);
    padding: 32px;
    border-radius: 24px;
    max-width: 411px;
    width: 100%;

    @include respond(tablet) {
      max-width: unset;
      padding: 24px 16px;
    }

    &__icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }

      @include respond(tabletS) {
        width: 66px;
        height: 66px;
      }
    }

    &__flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 32px;
      width: 100%;

      @include respond(tabletS) {
        row-gap: 16px;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
    }

    &__coinName {
      @include tTitle_1_semibold;
      font-weight: 600;

      @include respond(tabletS) {
        font-size: 18px;
      }
    }

    &__body {
      border: 1px solid var(--border_14);
      border-radius: 16px;
      width: 100%;

      &__row {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          @include tButton_2;
          font-weight: 400;
          margin: 0;
          color: var(--primary);
        }

        &:first-child {
          border-bottom: 1px solid var(--border_14);
        }
      }
    }
  }
}
