@use "src/_app/styles/mixins.scss" as *;
.container {
  padding: 100px 80px;
  background: var(--black);

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
  }

  .header {
    max-width: 1280px;
    width: 100%;
    height: 621px;
    position: relative;
    overflow: hidden;
    background-color: #141414;
    background-image:
      url('/icons/mass-payout/mass-payout-header-back-right.png'),
      url('/icons/mass-payout/mass-payout-header-back-left.png');
    background-repeat: no-repeat;
    background-position:
      365px 0,
      0 0;
    background-size: 100%;
    border-radius: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 48px;
    margin: 0 auto;

    @include rtl {
      flex-direction: row-reverse;
    }

    @include respond(laptop) {
      padding: 0 32px;
    }

    @include respond(tablet) {
      padding: 0;
      background: transparent;
      flex-direction: column;
      height: fit-content;
      align-items: center;
      row-gap: 32px;

      @include rtl {
        flex-direction: column;
      }
    }

    .header__content {
      color: var(--white);
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      max-width: 45%;
      margin: auto 0;

      @include rtl {
        padding-right: 48px;
      }

      @include respond(tablet) {
        max-width: 100%;
      }

      .content__title {
        margin: 0;

        @include tTitle_1_banner;
        color: var(--white);

        &.long {
          overflow: hidden;
          word-wrap: break-word;
          hyphens: auto;
        }

        @include respond(laptopL) {
          @include tTitle_1_main;
        }

        @include respond(tablet) {
          @include tTitle_2_bold;
        }

        b {
          @include tTitle_1_banner;
          display: block;

          @include respond(laptopL) {
            @include tTitle_1_main;
            display: inline;
          }

          @include respond(tablet) {
            @include tTitle_2_bold;
          }
        }
      }

      .content__description {
        margin: 0;

        @include tSubtitle;
        max-width: 90%;

        b {
          @include tSubtitle_Semibold;
        }

        @include respond(tablet) {
          max-width: 100%;
        }
      }

      .content__commission {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        column-gap: 6px;

        @include tSubtitle;

        svg path {
          fill: var(--white);
          stroke: var(--black);
        }
      }
    }
  }

  .images__list {
    position: absolute;
    top: 43px;
    left: calc(50% + 2px);
    width: 638px;
    height: 578px;

    @include respond(tablet) {
      display: none;
    }
  }

  .images__badge {
    position: absolute;
    top: 322px;
    left: calc(50% - 90px);
    z-index: 1;

    @include respond(tablet) {
      display: none;
    }
  }

  .images__progress {
    position: absolute;
    top: 407px;
    left: calc(50% - 90px);
    z-index: 1;

    @include respond(tablet) {
      display: none;
    }
  }

  .images__mobile {
    display: none;
    max-width: 343px;

    @include respond(tablet) {
      display: block;
      position: relative;
      max-height: 420px;

      g {
        transform: translate(-8px, -34px);
      }
    }

    @include respond(mobileL) {
      transform: scale(0.9) translateY(-23px);
    }
  }

  .mobile_wrapper {
    @include respond(mobileL) {
      max-height: 378px;
    }
  }

  .content__button {
    padding: 14px 20px;
    font-weight: 600;

    &.desktop {
      display: block;
      width: fit-content;
      margin-top: 16px;

      @include respond(tablet) {
        display: none;
      }
    }

    &.mobile {
      width: 100%;
      display: none;
      margin-top: 24px;

      @include respond(tablet) {
        display: block;
      }
    }
  }
}
