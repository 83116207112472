@use "src/_app/styles/mixins.scss" as *;
.integrate {
  display: flex;
  flex-direction: column;
  row-gap: 56px;

  @include respond(laptop) {
    row-gap: 32px;
    position: relative;
  }

  &::after {
    @include respond(laptop) {
      content: '';
      position: absolute;
      width: 191px;
      height: 248px;
      inset-inline-start: -95px;
      bottom: calc(100% + 108px);
      border-radius: 200px;
      background: #7a7a7a;
      filter: blur(200.75px);
      transform: translate3d(0, 0, 0);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    .header__title {
      margin: 0;

      @include tTitle_1_block;

      @include respond(laptop) {
        font-size: 36px;
        line-height: 42px;
      }
    }

    .header__description {
      margin: 0;

      @include tTitle_3-semibold;

      @include respond(laptop) {
        @include tSubtitle;
      }
    }
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 24px;
    justify-content: space-between;

    @include respond(laptop) {
      flex-direction: column;
      row-gap: 32px;
    }

    .list__item {
      display: flex;
      flex-direction: column;
      width: 382px;
      padding: 40px 16px 0;
      position: relative;

      @include respond(laptop) {
        width: 100%;
      }

      .item__icon {
        position: absolute;
        top: -7px;
        inset-inline-start: -3px;
        z-index: 9;
      }

      .item__title {
        z-index: 10;

        @include Land_32;
        margin: 0 0 16px;

        @include respond(laptop) {
          font-size: 26px;
          line-height: 36px;
        }
      }

      .item__description {
        z-index: 10;

        @include tSubtitle;
      }
    }
  }
}
