@use "src/_app/styles/mixins.scss" as *;
.container {
  background-color: var(--bg_6);
  border-radius: 24px;

  @include respond(laptopC) {
    border-radius: 24px 0 0 24px;
  }

  @include respond(tablet) {
    border-radius: 24px;
    box-shadow:
      0 16px 28px 0 rgb(37 33 41 / 4%),
      0 0 16px 0 rgb(37 33 41 / 4%);
  }

  .header {
    border-bottom: 1px solid var(--border_13);

    .action {
      width: 13px;
      height: 13px;
      border-radius: 50%;

      &:first-child {
        background-color: var(--negative);
      }

      &:nth-child(2) {
        background-color: var(--warning);
      }

      &:nth-child(3) {
        background-color: var(--success);
      }

      &__wrapper {
        display: flex;
        column-gap: 11px;

        padding: 16px 24px;
      }
    }
  }

  .body {
    padding: 24px 32px;

    @include respond(laptopC) {
      padding: 24px;
    }

    @include respond(tabletS) {
      padding: 16px;
    }
  }
}
