@use "src/_app/styles/mixins.scss" as *;
.faq {
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  color: #fff;
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 80px;

  @include respond(laptop) {
    padding: 120px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
  }

  @include respond(tablet) {
    row-gap: 36px;
  }

  & * {
    color: #fff;
  }

  & div::before,
  div::after {
    background-color: #fff !important;
  }

  & > div > div {
    border-bottom: 1px solid var(--Borders-Primary, #2d2d2d);
  }

  &__title {
    max-width: 600px;

    margin: 0;

    @include tTitle_1_block;

    @include respond(tablet) {
      @include Land_32;
    }
  }
}
