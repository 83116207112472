@use "src/_app/styles/mixins.scss" as *;
.which_better {
  display: flex;
  flex-direction: column;
  row-gap: 68px;
  max-width: 980px;
  margin: 0 auto;
  color: var(--primary);

  @include respond(tablet) {
    row-gap: 32px;
  }

  &__title {
    margin: 0;

    @include tTitle_1_main;

    @include respond(tablet) {
      @include tTitle_2_Semibold;
    }
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    column-gap: 32px;
    align-items: center;

    @include respond(tablet) {
      flex-direction: column;
      row-gap: 24px;
    }

    .content__icons {
      flex-shrink: 0;
      width: 482px;
      height: 450px;
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: 12px;

      @include respond(tablet) {
        width: 100%;
        height: fit-content;
        align-items: flex-start;
      }

      .icon {
        position: absolute;
        border-radius: 24px;

        @include respond(tablet) {
          border-radius: 16px;
          position: static;
        }

        &.icon_convert {
          left: 0;
          bottom: 0;
          z-index: 0;
        }

        &.icon_buy {
          right: 0;
          top: 0;
          z-index: 1;
          box-shadow: -10px 0 80px 0 rgb(0 0 0 / 10%);

          @include respond(tablet) {
            box-shadow: none;
          }
        }
      }
    }

    .content__text {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      max-width: 40%;

      @include respond(laptop) {
        max-width: 50%;
      }

      @include respond(tablet) {
        max-width: 100%;
      }

      .text__title {
        @include tTitle_2_semibold;
        margin: 0;

        @include respond(tablet) {
          line-height: 32px;
        }
      }

      .text__description {
        @include tSubtitle;
        margin: 0;

        @include respond(tablet) {
          @include tSubtitle;
          font-size: 14px;
        }
      }
    }
  }
}
