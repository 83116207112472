@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  position: relative;
  box-shadow: 4px 4px 16px 6px rgb(0 0 0 / 14%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 165px;
    background: linear-gradient(-180deg, #000 0%, transparent 100%);
    z-index: 10;
    border-radius: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: radial-gradient(
      100% 66.64% at 50% 50%,
      rgb(52 52 52 / 100%) 100%,
      rgb(52 52 52 / 100%) 100%,
      rgb(10 10 10 / 100%) 100%
    );
    /* stylelint-disable-next-line */
    mask:
      linear-gradient(#2d2d2d 0 0) content-box,
      linear-gradient(#525252 0 0);
    /* stylelint-disable-next-line */
    mask-composite: xor;
    /* stylelint-disable-next-line */
    mask-composite: exclude;
    z-index: 10;
  }
}

.container {
  position: relative;
  padding: 48px;
  display: flex;
  flex-flow: column;
  gap: 24px;
  text-align: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(10 10 10 / 24%) 0%,
    rgb(82 82 82 / 24%) 100%
  );
  box-shadow: 4px 4px 16px 6px #00000024;
  border: 1px solid;
  border-image-source: radial-gradient(
    50% 66.64% at 50% 50%,
    #343434 0%,
    #343434 50%,
    #0a0a0a 100%
  );
  border-image-slice: 1;
  border-radius: 20px;
  overflow: hidden;

  @include respond(tablet) {
    padding: 30px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.09;
    background: url('/icons/services/trading/profit-noise-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  h2 {
    margin: 0;
    max-width: 592px;
    color: var(--white);
    z-index: 11;

    @include tTitle_1_main;

    @include respond(tabletS) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  a {
    width: max-content;
    min-width: 200px;
    height: 56px;
    padding: 16px 24px;
    border-radius: 10px;
    background: var(--white);
    text-align: center;
    color: #0a0a0a;

    @include tSubtitle;

    @include respond(tabletS) {
      margin: 8px auto 0;
      height: 40px;
      width: 100%;
      min-width: 200px;
      padding: 8px 16px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 153px;
    height: 153px;
    top: 30%;
    left: 5%;
    background-image: url('/icons/services/trading/exchange-profit-mask.svg');
    background-size: 100%;
    filter: blur(10px);
    transform: translate3d(0, 0, 0);

    @include respond(tablet) {
      top: auto;
      bottom: 0;
      left: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 349px;
    height: 349px;
    top: -135px;
    right: -135px;
    border-radius: 200px;
    background: linear-gradient(128.22deg, #111 14.01%, #777 150.41%);
    filter: blur(20px);
    transform: translate3d(0, 0, 0);

    @include respond(tablet) {
      display: none;
    }
  }

  .container__info__sign_up {
    position: relative;
    z-index: 99;
  }
}
