@use "src/_app/styles/mixins.scss" as *;
.container {
  background: var(--secondary);
  padding: 100px 80px;

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
  }

  &.stepsContainer {
    background-color: var(--bg_mass_block);
    color: var(--primary);
    padding-bottom: 0;
    overflow: hidden;

    .content {
      @include contentWidth;
      max-width: 980px;
      margin: 0 auto;

      @include respond(tablet) {
        padding-bottom: 16px;
      }
    }
  }

  &.blog {
    $pad: 26px;

    background: var(--bg_26);
    color: var(--primary);

    .blog__content {
      @include contentWidth;
      display: flex;
      flex-direction: column;

      h3 {
        @include tTitle_1_main;
        margin: 0 0 56px;

        @include respond(laptop) {
          @include tTitle_2_main;
        }

        @include respond(tabletS) {
          @include tTitle_1_demibold;
          margin-bottom: 30px;
        }
      }

      .swiper_item_container {
        padding: 0;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .swiper_item {
          border-radius: 16px;
          overflow: hidden;
          padding: $pad;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: var(--bg_6);

          @include sElevation_5;

          h3 {
            margin-top: 16px;
            margin-bottom: 8px;

            @include tTitle_2_semibold;
          }

          p {
            margin: 0;

            @include tCaption;
            color: var(--text_1);
          }
        }
      }

      .swiper_list {
        overflow: visible;
      }
    }
  }

  &.how_it_works_accordion {
    background: var(--bg_mass_block);

    .how_it_works__wrapper {
      max-width: 980px;
      margin: 0 auto;
    }
  }

  &.advantages {
    background: var(--black);
    color: var(--white);
  }

  .how_it_works {
    color: var(--primary);

    @include contentWidth;
    display: flex;
    flex-direction: column;
    row-gap: 100px;

    @include respond(tablet) {
      row-gap: 16px;
    }

    &__title {
      margin: 0;
      letter-spacing: normal;
      line-height: 72px;
      text-align: center;

      @include tTitle_1_bold;

      @include respond(laptop) {
        @include tTitle_1_main;
      }

      @include respond(tablet) {
        @include tTitle_2_Semibold;
        text-align: start;
      }
    }
  }

  &.chooseUs {
    background: var(--black);
    color: var(--white);
  }

  &.whenToUse {
    background: var(--catalog_bg_2);
    overflow-x: hidden;
  }

  &.which_better {
    background-image: var(--convert_better_background_url);
    background-size: contain;
    background-position: center;
    background-repeat: repeat;

    @include respond(tablet) {
      background-image: none;
    }
  }

  &.differenceBlock {
    background-color: var(--bg_convert_difference);
    background-image: url('/public/img/common/bg-stars-convert.png');
    background-repeat: no-repeat;
    background-position: top 75px center;
  }

  &.coinsSlider {
    background: var(--catalog_bg_2);

    @include respond(tablet) {
      padding: 40px 0;
    }
  }
}

.converter {
  /* stylelint-disable-next-line */
  background:
    radial-gradient(#242424 2px, transparent 0),
    linear-gradient(180deg, #000 0%, #161616 100%);
  background-color: var(--black);
  background-size:
    106px 106px,
    100%;
  background-position:
    -56px -27px,
    center;

  @include respond(tablet) {
    background-image: none;
  }
}

.alwaysDark {
  background-color: var(--black);
  color: var(--white);
}
