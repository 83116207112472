@use "src/_app/styles/mixins.scss" as *;
.tools {
  padding: 100px 0;
  gap: 56px !important;

  @include respond(laptopL) {
    padding: 100px 80px;
    margin: 0;
  }

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 100px 32px !important;
    gap: 32px !important;
  }

  @include respond(tabletS) {
    padding: 40px 16px !important;
  }
}
