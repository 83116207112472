@use "src/_app/styles/mixins.scss" as *;
.staking__start {
  padding-bottom: 100px;
  border-bottom: 1px solid var(--border_1);

  @include respond(tablet) {
    padding: 0;
    border-bottom: none;
  }

  &__title {
    margin: 0 auto;
    max-width: 796px;
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;

    @include respond(laptop) {
      font-size: 48px;
      line-height: 60px;
    }

    @include respond(mobileL) {
      font-size: 28px;
      line-height: 36px;
      text-align: left;
    }
  }

  &__steps {
    &__block {
      position: relative;
      padding: 32px 50px 65px;
      border-radius: 24px;
      display: flex;
      column-gap: 41px;
      background-color: var(--bg_3);
      margin: 68px 0;
      height: 590px;

      @include respond(laptopL) {
        column-gap: 20px;
      }

      @include respond(laptopC) {
        padding: 32px 32px 65px;
        column-gap: 32px;
      }

      @include respond(laptop) {
        margin: 56px 0 0;
        height: auto;
      }

      @include respond(tablet) {
        margin: 32px 0;
        padding: 0;
        display: block;
        height: 100%;
        background-color: transparent;
      }
    }

    &__col {
      &_left {
        display: flex;
      }

      &_right {
        width: 100%;
      }
    }
  }
}
