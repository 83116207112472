@use "src/_app/styles/mixins.scss" as *;
.accordion-wrapper {
  margin-bottom: 100px;
  width: 100%;
  padding: 100px 80px;
  background: var(--bg_mass_block);

  @include respond(laptop) {
    margin-bottom: 32px;
    padding: 100px 32px;
  }

  @include respond(tablet) {
    margin-bottom: 40px;
    padding: 40px 16px;
  }

  @include respond(tablet) {
    row-gap: 16px;
  }

  .explorer_accordion {
    max-width: 980px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    color: var(--primary);

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: var(--primary);
    }
  }
}
