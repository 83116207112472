@use "src/_app/styles/mixins.scss" as *;
.walletForm {
  display: block;
  width: 110%;
  height: 110%;
  padding-top: 20px;
  background: var(--secondary);
  transform: translateX(-5%);

  @include rtl {
    transform: translateX(5%);
  }
  pointer-events: none;
}
