@use "src/_app/styles/mixins.scss" as *;
.container {
  background: var(--black);
  padding: 124px 80px;
  background-image: url('/img/mass-payout/mass-payouts-back-last.png');
  background-size: 1420px;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-top: 1px solid #2d2d2d;

  @include respond(laptop) {
    padding: 100px 32px;
    background-size: 1350px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
    background-position: 50% 20%;
  }

  @include respond(mobileL) {
    background-position: 50% -22%;
  }

  .get_started {
    max-width: 1280px;
    margin: 0 auto;
    color: var(--white);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__title {
      margin: 0 0 16px;

      @include tTitle_1_main;
      max-width: 60%;

      @include respond(laptopL) {
        max-width: 80%;
      }

      @include respond(tablet) {
        @include tTitle_2_Semibold;
        max-width: 100%;
      }
    }

    &__description {
      margin: 0 0 32px;

      @include tSubtitle;

      @include respond(tablet) {
        margin-bottom: 24px;
      }
    }

    &__btns {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      gap: 16px;

      a.login__button {
        &:hover {
          background: #1a1a1a;
        }
        color: #fff;
        border: 1px solid #fff;
      }

      @include respond(tablet) {
        gap: 9px;
      }

      a {
        font-weight: 500;
        min-width: 145px;
        padding: 14px 20px;
        text-transform: capitalize;
      }
    }
  }
}
