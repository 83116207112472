@use "src/_app/styles/mixins.scss" as *;
.stake {
  &__steps {
    display: flex;
    flex-direction: column;
    row-gap: 43px;
    margin-top: 39px;

    @include respond(laptopC) {
      row-gap: 20px;
    }

    @include respond(tablet) {
      margin-top: 0;
      border-radius: 16px;
      row-gap: 16px;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    column-gap: 20px;

    @include respond(laptop) {
      column-gap: 16px;
    }

    @include respond(tablet) {
      background-color: var(--secondary);
      border-radius: 16px;
      padding: 16px;
      height: 94px;
    }

    &__index {
      position: relative;

      p {
        width: 32px;
        height: 32px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--primary);
        color: var(--secondary);

        @include tSubtitle_Semibold;
      }
    }

    &__border {
      width: 1px;
      height: 120%;
      border-left: 1px dashed var(--primary);
      position: absolute;

      left: 15px;
      top: 55px;

      @include respond(laptopC) {
        height: 100%;
      }

      @include respond(laptop) {
        height: 140px;
      }

      @include respond(tablet) {
        display: none;
      }
    }

    &__text__block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
      row-gap: 4px;

      background-color: var(--bg_30);
      border-radius: 16px;

      width: 100%;
      height: 80px;

      @include respond(laptop) {
        height: 140px;
      }

      @include respond(tablet) {
        padding: 0;
        height: 100%;
        background-color: transparent;
      }
    }

    &__title {
      margin: 0;
      color: var(--primary);

      @include tSubtitle_Semibold;

      @include respond(tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__text {
      margin: 0;
      color: var(--primary);

      @include tCaption;

      @include respond(tablet) {
        font-size: 14px;
      }
    }
  }
}
