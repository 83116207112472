@use "src/_app/styles/mixins.scss" as *;
.stake {
  &__history {
    display: flex;
    flex-direction: column;
    background-color: var(--secondary);

    border-radius: 16px;

    @include sElevation_7;
    width: max-content;
    height: max-content;

    position: absolute;
    top: 71px;

    @include respond(laptopC) {
      display: none;
    }

    &__title {
      padding: 20px 24px 18px;

      @include tTitle_3-semibold;
      color: var(--primary);
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;

    &__tr {
      display: grid;
      grid-template-columns: 82px max-content;
      column-gap: 48px;
      padding: 16px 24px;

      p {
        margin: 0;

        @include tSubtitle;
      }

      &_gray {
        background-color: var(--bg_27);
      }

      &:nth-child(n + 2):not(:last-child) {
        border-bottom: 1px solid var(--border_11);
      }
    }

    &__td {
      color: var(--primary);

      @include tSubtitle;
    }
  }
}
