@use "src/_app/styles/mixins.scss" as *;
.accordion {
  @include contentWidth;
  color: var(--primary);
  -webkit-tap-highlight-color: transparent;

  &_padding {
    @include respond(laptopL) {
      padding: 0 32px;
    }

    @include respond(tabletS) {
      padding: 0 16px;
    }
  }

  &__item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    border-bottom: 1px solid var(--border_10);
    cursor: pointer;

    @include respond(tabletS) {
      padding-top: 20px;
    }

    .content {
      margin: 0;

      @include tSubtitle;
      padding-bottom: 24px;

      @include respond(tabletS) {
        padding-bottom: 20px;
      }

      strong {
        font-weight: 600;
      }
    }

    .content__list {
      padding: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
    }

    .item__header {
      display: flex;
      justify-content: space-between;

      h3 {
        @include tTitle_3-semibold;
        margin: 0 0 24px;
        max-width: 80%;

        @include respond(tabletS) {
          margin-bottom: 20px;
        }
      }

      .plus {
        width: 16px;
        height: 16px;
        position: relative;
      }

      .plus::before,
      .plus::after {
        content: '';
        position: absolute;
        background-color: var(--primary);

        @include transition;
        border-radius: 2px;
      }

      .plus::before {
        width: 16px;
        height: 2px;
        top: 7.5px;
        left: 0;
      }

      .plus::after {
        width: 2px;
        height: 16px;
        top: 1px;
        left: 7px;
        transform: scaleX(1.1);
        transform-origin: center;

        @include respond(laptop) {
          transform: scaleX(1);
        }
      }

      .plus.minus::after {
        transform: scaleY(0);
      }
    }
  }
}
