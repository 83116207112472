@use "src/_app/styles/mixins.scss" as *;
.container {
  @include contentWidth;
  color: white;
  background: var(--bg_mass_block);
  border-radius: 24px;
  padding: 48px;
  overflow: hidden;

  @include respond(laptop) {
    padding: 32px 0 32px 32px;
  }

  @include respond(tablet) {
    padding: 0;
    background: transparent;
    border-radius: 0;
  }

  .content {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) 578px;
    grid-template-areas: 'text image';
    align-items: flex-start;
    color: black;
    gap: 48px;

    @include rtl {
      grid-template-areas: 'image text';
      grid-template-columns: minmax(400px, 578px) minmax(250px, 1fr);
    }

    @include rtlAndRespond(laptop) {
      grid-template-areas: 'image text';
      grid-template-columns: minmax(350px, 467px) minmax(250px, 1fr);
      gap: 32px;
    }

    @include rtlAndRespond(tablet) {
      grid-template-areas: 'text';
      grid-template-columns: 1fr;
    }

    @include respond(laptop) {
      grid-template-columns: minmax(250px, 1fr) 467px;
      gap: 32px;
    }

    @include respond(tablet) {
      grid-template-areas: 'text';
      grid-template-columns: 1fr;
    }

    .description {
      align-items: flex-start;
      grid-area: text;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      row-gap: 32px;
      color: var(--primary);

      .title {
        @include tTitle_1_form;
        margin: 0 0 16px;

        @include respond(laptop) {
          @include Land_28;
          margin-bottom: 30px;
        }

        @include respond(tablet) {
          margin: 0;
        }
      }
    }
  }

  .top {
    width: 100%;
  }

  .accordion {
    padding: 0;
    margin: 0;

    .item {
      list-style: none;
      padding: 24px 0;
      cursor: pointer;
      overflow: hidden;
      border-bottom: 1px solid var(--line_color);

      .itemHeader {
        display: flex;
        justify-content: space-between;

        .itemTitle {
          @include tTitle_2_semibold;
          font-size: 22px;
          letter-spacing: -0.44px;
          margin: 0;

          @include respond(laptop) {
            @include tTitle_3-semibold;
            letter-spacing: -0.24px;
          }

          @include respond(tablet) {
            font-size: 16px;
          }
        }

        .plus {
          width: 16px;
          height: 16px;
          position: relative;
          flex-shrink: 0;
        }

        .plus::before,
        .plus::after {
          content: '';
          position: absolute;
          background-color: var(--primary);

          @include transition;
          border-radius: 2px;
        }

        .plus::before {
          width: 16px;
          height: 2px;
          top: 7.5px;
          left: 0;
        }

        .plus::after {
          width: 2px;
          height: 16px;
          top: 1px;
          left: 7.2px;
          transform: scaleX(1.1);
          transform-origin: center;

          @include respond(laptop) {
            transform: scaleX(1);
          }
        }

        .plus.minus::after {
          transform: scaleY(0);
        }
      }

      .itemText {
        display: block;

        @include tSubtitle;
        margin-top: 8px;

        @include respond(tablet) {
          padding-right: 28px;
        }
      }
    }
  }

  .image {
    grid-area: image;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 446px;

    @include respond(tablet) {
      display: none;
    }

    .img {
      border-radius: 24px;
    }
  }

  a.bottom__btn {
    padding: 14px 20px;
    min-width: 143px;
  }
}
