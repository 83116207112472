@use "src/_app/styles/mixins.scss" as *;
$blur-width: 68px;

.advantages {
  background: var(--bg_mass_block);
  padding: 100px 80px;
  color: var(--primary);

  @include respond(laptop) {
    padding: 100px 32px;
  }

  @include respond(tablet) {
    padding: 40px 16px;
  }

  .content {
    max-width: calc(1280px + 80px * 2);
    margin: auto;
  }

  .header {
    @include contentWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 68px;
    column-gap: 24px;

    @include respond(laptop) {
      margin-bottom: 56px;
    }

    @include respond(tablet) {
      margin-bottom: 24px;
    }

    .title {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      margin: 0;

      @include respond(laptop) {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
      }

      @include respond(tablet) {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .buttons {
      display: flex;
      column-gap: 24px;

      @include respond(tablet) {
        display: none;
      }

      .control {
        color: var(--primary);
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid var(--border_1);

        @include ltr {
          transform: rotate(180deg);
        }
        cursor: pointer;

        @include transition;

        &.forward {
          @include ltr {
            transform: rotate(0);
          }

          @include rtl {
            transform: rotate(180deg);
          }
        }

        &:hover {
          opacity: 0.5;
        }

        &:active {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  .swiperWrapper {
    position: relative;
    max-width: 1280px;
    margin: auto;

    // @include contentWidth;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 80px;
      height: 100%;
      z-index: 2;
      top: 0;

      @include respond(tablet) {
        width: 16px;
      }
    }

    &::before {
      left: -$blur-width;

      // background: linear-gradient(to right, var(--bg_15), transparent);
      background: linear-gradient(
        to left,
        var(--bg_mass_grad),
        var(--bg_mass_block)
      );

      @include respond(laptop) {
        left: -32px;
      }

      @include respond(tablet) {
        left: -16px;
      }
    }

    &::after {
      background: linear-gradient(
        to right,
        var(--bg_mass_grad),
        var(--bg_mass_block)
      );

      right: -$blur-width;

      @include respond(laptop) {
        right: -32px;
      }

      @include respond(tablet) {
        right: -16px;
      }
    }

    .swiper {
      margin-left: -$blur-width;
      padding-left: $blur-width;
      margin-right: -$blur-width;
      padding-right: $blur-width;

      @include respond(laptop) {
        margin-left: -32px;
        padding-left: 32px;
        margin-right: -32px;
        padding-right: 32px;
      }

      @include respond(tablet) {
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: -16px;
      }
    }
  }

  .item {
    height: 100%;
  }
}
