@use "src/_app/styles/mixins.scss" as *;
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 24px;

  @include respond(tabletS) {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  }

  &__item {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 32px;
    background:
      linear-gradient(0deg, #141414, #141414),
      linear-gradient(0deg, rgb(0 0 0 / 10%), rgb(0 0 0 / 10%));
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 24px;

    @include transition;
    cursor: pointer;
    height: max-content;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 24px;
      padding: 1px;
      background: var(--border__processing_advantages);
      /* stylelint-disable-next-line */
      mask:
        linear-gradient(#141414 0 0) content-box,
        linear-gradient(#141414 0 0);
      mask-composite: exclude;
    }

    &:hover {
      background: #292929;
    }

    &:active {
      transform: scale(0.95);
    }

    @include respond(tabletS) {
      padding: 24px;
    }

    .item__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .item__title {
        @include tTitle_2_semibold;
        margin: 0;
      }

      .item__icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #292929;
        border-radius: 50%;
        color: #c5c5c5;

        @include transition;

        svg {
          transform: scale(0.75);
        }
      }
    }

    .item__description {
      margin: 0;

      @include tSubtitle;
      max-width: 70%;

      @include respond(tabletS) {
        max-width: 100%;
      }
    }
  }
}
