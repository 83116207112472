@use "src/_app/styles/mixins.scss" as *;
.slider {
  &__container {
    position: relative;

    &::before {
      left: 0;
      background: var(--bg_45);

      @include respond(laptopC) {
        display: none;
      }
    }

    &::after {
      right: 0;
      transform: rotate(180deg);
      background: var(--bg_45);

      @include respond(laptop) {
        display: none;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50px; /* Ширина градиента */
      z-index: 99;

      @include respond(tablet) {
        width: 16px;
      }
    }

    @include respond(tablet) {
      padding: 0 0 0 16px;
    }
  }

  &__swiper {
    overflow: hidden;
    width: 100%;

    .swiper_slide {
      padding: 0;
    }
  }
}
