@use "src/_app/styles/mixins.scss" as *;
.how_it_works {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  color: var(--primary);

  @include respond(tablet) {
    row-gap: 16px;
  }

  &__title {
    margin: 0;

    @include tTitle_1_form;
  }
}
