@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  flex-flow: column;
  gap: 36px;
  color: var(--white);

  & > h2 {
    margin: 0;

    @include tTitle_1_block;

    @include respond(tablet) {
      font-size: 32px;
      line-height: 42px;
    }
  }
}
