@use "src/_app/styles/mixins.scss" as *;
.container {
  display: flex;
  flex-flow: column;
  gap: 36px;
  color: var(--white);

  @include respond(tablet) {
    gap: 24px;
  }

  h2 {
    margin: 0;

    @include tTitle_1_block;

    @include respond(tablet) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include respond(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 16px;
    }

    &__item {
      display: grid;
      grid-template-columns: 1fr 48px;
      gap: 13px;
      border-radius: 20px;
      padding: 24px;
      cursor: pointer;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 20px;
        padding: 1px;
        background: radial-gradient(
          80% 66% at 50% 50%,
          rgb(26 26 26 / 100%) 100%,
          rgb(82 82 82 / 100%) 100%,
          rgb(10 10 10 / 100%) 100%
        );
        /* stylelint-disable-next-line */
        mask:
          linear-gradient(#2d2d2d 0 0) content-box,
          linear-gradient(#525252 0 0);
        /* stylelint-disable-next-line */
        mask-composite: xor;
        /* stylelint-disable-next-line */
        mask-composite: exclude;
      }

      @include respond(tablet) {
        padding: 16px;
        gap: 12px;
      }

      &__info {
        display: flex;
        gap: 12px;

        &__icon {
          width: 32px;
        }

        h3 {
          margin: 0;

          @include Land_32;

          @include respond(tablet) {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
          }
        }

        p {
          @include tTitle_1_semibold;

          @include respond(tablet) {
            @include tSubtitle;
          }
        }
      }

      &__link_area {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
      }
    }
  }
}
