@use "src/_app/styles/mixins.scss" as *;
.container {
  margin: 0 auto 56px;
  max-width: 980px;
  width: 100%;
  padding: 24px 48px;
  display: flex;
  gap: 36px;
  border: 1px solid #272727;
  border-radius: 20px;
  background: #141414;
  color: var(--white);
  align-items: center;

  @include respond(tabletL) {
    margin: 0 auto 40px;
    width: 500px;
    padding: 16px;
    flex-flow: column;
    gap: 12px;
  }

  @include respond(tabletS) {
    max-width: 329px;
    width: 100%;
    padding: 16px 0;
    flex-flow: column;
    gap: 12px;
  }

  h2 {
    margin: 0;

    @include tTitle_1_main;

    @include respond(tabletL) {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      text-align: center;
    }
  }

  &__links {
    margin-inline-start: auto;
    display: flex;
    gap: 24px;

    @include respond(tabletL) {
      margin: 0 auto;
    }
  }
}
