@use "src/_app/styles/mixins.scss" as *;
.faq {
  max-width: 976px;
  padding: 100px 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  color: var(--primary);

  @include respond(tabletS) {
    padding: 40px 16px;
    row-gap: 16px;
  }

  &__title {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;

    @include respond(laptop) {
      font-size: 40px;
      line-height: 50px;
    }

    @include respond(tabletS) {
      font-family: Golos, sans-serif;
      font-size: 28px;
      line-height: 36px;
    }
  }
}
