@use "src/_app/styles/mixins.scss" as *;
.container {
  display: grid;
  gap: 57px;
  grid-template-columns: minmax(250px, 458px) 1fr;
  align-items: center;
  color: var(--white);

  @include respond(laptop) {
    display: flex;
    flex-flow: column-reverse;
  }

  &__info {
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 16px;

    @include respond(laptop) {
      margin-top: -120px;
      text-align: center;
    }

    h2 {
      margin: 0;

      @include tTitle_1_block;

      @include respond(tablet) {
        font-size: 32px;
        line-height: 42px;
      }
    }

    p {
      margin: 0;
      font-size: 24px;
      line-height: 32px;

      @include respond(tablet) {
        @include tSubtitle;
      }
    }

    a {
      margin-top: 20px;
      width: max-content;
      min-width: 200px;
      height: 56px;
      padding: 16px 24px;
      border-radius: 10px;
      background: var(--white);
      text-align: center;

      color: #0a0a0a;

      @include tSubtitle;

      @include respond(laptop) {
        margin: 8px auto 0;
        height: 40px;
        padding: 8px 16px;
      }
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 50px;
      left: 0;
      margin-inline-start: 90%;
      width: 300px;
      height: 300px;
      border-radius: 300px;
      background: #343434;
      filter: blur(100px);
      transform: translate3d(0, 0, 0);

      @include respond(laptop) {
        display: none;
      }
    }
  }

  img {
    margin-inline-start: auto;
    position: relative;
    z-index: 10;

    @include respond(laptopC) {
      height: 350px;
    }

    @include respond(laptop) {
      margin-inline-start: 0;
      height: auto;
      max-width: 100%;
    }
  }
}
