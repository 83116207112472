@use "src/_app/styles/mixins.scss" as *;
.staking {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 375px;
  padding: 20px 16px;
  border-radius: 16px;
  box-shadow: -10px 0 80px 0 var(--border_11);
  background-color: var(--secondary);
  z-index: 2;
  position: relative;
  margin-left: 204px;

  @include respond(laptopL) {
    margin-left: 120px;
  }

  @include respond(laptopC) {
    margin-left: 0;
  }

  @include respond(laptop) {
    width: 300px;
  }

  @include respond(tablet) {
    display: none;
  }

  &__title {
    @include tTitle_3-semibold;
    color: var(--primary);
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .stake__now__btn {
      button {
        width: 100%;
      }
    }
  }

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 12px 16px;
    border-radius: 10px;
    border: 1px solid #ddd;

    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__label {
      color: var(--primary);

      @include tCaption;
      letter-spacing: -0.12px;
      margin: 0;
    }

    &_wallet {
      display: flex;
      align-items: center;
      column-gap: 12px;

      p {
        margin: 0;
        color: var(--primary);

        @include tSubtitle;
        letter-spacing: -0.24px;
      }
    }

    &_amount {
      p {
        margin: 0;
        color: var(--coinName);

        @include tSubtitle;
        letter-spacing: -0.24px;

        span {
          color: var(--primary);
        }
      }
    }

    button {
      border: none;
      outline: none;
      background: none;

      color: var(--primary);

      @include tSubtitle;
      letter-spacing: -0.24px;

      display: flex;
      align-items: center;

      svg path {
        fill: var(--primary);
      }
    }

    &__limit {
      @include tCaption;
      letter-spacing: -0.12px;
      line-height: 22px;
      color: var(--text_6);
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__dates {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 16px;
    background-color: var(--bg_28);
    border-radius: 10px;

    &__tr {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      margin: 0;
      color: var(--text_5);

      @include tCaption;
      line-height: 22px;
      letter-spacing: -0.12px;

      @include respond(laptop) {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__date {
      margin: 0;
      color: var(--primary);

      @include tCaption;
      line-height: 22px;
      letter-spacing: -0.12px;
    }
  }

  &__percentage {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: var(--primary);

      @include tCaption;
      line-height: 22px;
      letter-spacing: -0.12px;
      margin: 0;
      font-family: Golos, sans-serif;
    }
  }
}
