@use "src/_app/styles/mixins.scss" as *;
.top_container {
  background: var(--mainPrimary);
  color: var(--mainSecondary);

  .top__content {
    padding: 100px 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(tabletL) {
      padding: 40px 16px;
      align-items: flex-start;
    }

    h1 {
      max-width: 1102px;

      @include tTitle_1_banner;
      margin: 0 0 16px;
      display: inline;

      @include respond(tablet) {
        @include tTitle_2_bold;
        text-align: start;
      }
    }

    .subtitle {
      @include tTitle_1_banner;
      max-width: 750px;
      display: block;
      margin: 0 auto;

      @include respond(tablet) {
        @include tTitle_2_bold;
        text-align: start;
      }

      &.long {
        max-width: 1140px;
      }
    }

    p {
      margin: 0;
      white-space: break-spaces;

      @include tSubtitle_Semibold;

      &.goal {
        @include tTitle_2_semibold;
        max-width: 715px;

        @include respond(tablet) {
          @include tSubtitle;
          text-align: start;
        }
      }
    }
  }
}
